<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <vendor-list-box
      :columns="columns"
      :rows="ratings"
      :pagination="pagination"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No Feedback found."
      :showPagination="true"
      :searchItems="searchItems"
      :showDownload="true"
      :showToolbar="true"
      downloadText="Download Report"
      @download="onExportCSV"
    >
    </vendor-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import router from '@/router';
import _ from 'lodash';

import VendorListBox from '../../components/VendorListBox.vue';

export default {
  name: 'ratingList',
  components: { VendorListBox },
  metaInfo() {
    return {
      title: 'Rating Submission Report',
      meta: [
        {
          name: 'Feedback Report',
          content: `Manage Feedback Report`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(2, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Feedback Submitted Report',
      searchItems: ['Vendor Code', 'Vendor Name'],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Vendor Code',
          class: { 'text-center': true },
          textKey: 'vendorCode',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Vendor Name',
          class: { 'text-center': true },
          textKey: 'vendorName',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Ease of Getting Orders',
          class: { 'text-center': true },
          htmlKey: 'easeOfGettingOrder',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Ease of Supply',
          class: { 'text-center': true },
          htmlKey: 'easeOfSupply',
          width: '10%'
        },
        {
          type: 'string',
          headerText: ' Ease of Documentation while Delivery',
          class: { 'text-center': true },
          htmlKey: 'easeOfDocumentationWhileDelivery',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Ease of receiving Payments',
          class: { 'text-center': true },
          htmlKey: 'easeOfReceivingPayments',
          width: '10%'
        },
        {
          type: 'string',
          headerText: ' Accessibility for SRL leads around communication',
          class: { 'text-center': true },
          htmlKey: 'accessibilityForSRLaroundCommunication',
          width: '9%'
        },
        {
          type: 'string',
          headerText: ' Ease of account reconciliation',
          class: { 'text-center': true },
          htmlKey: 'easeOfAccountReconciliation',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Submitted On',
          class: { 'text-center': true },
          htmlKey: 'createdAt',
          width: '10%'
        }
      ]
    };
  },
  computed: {
    ...mapState('rating', ['loading', 'baseUrl', 'ratings', 'pagination', 'downloadRatings'])
  },
  watch: {
    downloadRatings(newValue) {
      if (newValue) {
        let csv =
          'Row Number,Vendor Code,Vendor Name,Please Rate Ease of Getting Orders,Please Rate Ease of Supply,Please Rate Ease of Documentation while Delivery,Please Rate Ease of receiving Payments,Please Rate Accessibility for SRL leads around communication,Please Rate Ease of account reconciliation,Submitted On\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'NPS Vendor Report.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('rating', ['list', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    }
  }
};
</script>

<style></style>
