<template>
  <div>
    <preloader class="overlay"></preloader>
    <div v-if="loading">
      <b-overlay :show="show" rounded="sm">
        <img class="logo" width="100px" src="../../assets/images/logo.png" alt="logo" />
        <h2 class="title">NPS</h2>
        <h3 class="feedback">Give Us Your Feedback.</h3>

        <label for="rating-lg" class="mt-3"><h5>1. Please rate the Ease of Getting Orders</h5></label>
        <b-form-rating id="rating-lg" no-border variant="primary" size="lg" v-model="value1"></b-form-rating>

        <label for="rating-lg" class="mt-3"><h5>2. Please rate the Ease of Supply</h5></label>
        <b-form-rating id="rating-lg" no-border v-model="value2" variant="primary" size="lg"></b-form-rating>

        <label for="rating-lg" class="mt-3"><h5>3. Please rate the Ease of Documentation while Delivery</h5></label>
        <b-form-rating id="rating-lg" no-border v-model="value3" variant="primary" size="lg"></b-form-rating>

        <label for="rating-lg" class="mt-3"><h5>4. Please rate the Ease of receiving Payments</h5></label>
        <b-form-rating id="rating-lg" no-border v-model="value4" variant="primary" size="lg"></b-form-rating>

        <label for="rating-lg" class="mt-3"
          ><h5>5. Please rate Accessibility for SRL leads around communication</h5></label
        >
        <b-form-rating id="rating-lg" no-border v-model="value5" size="lg" variant="primary"></b-form-rating>

        <label for="rating-lg" class="mt-3"><h5>6. Please rate the Ease of account reconciliation</h5></label>
        <b-form-rating id="rating-lg" no-border v-model="value6" size="lg" variant="primary"></b-form-rating>

        <b-button :disabled="show" class="submitButton" variant="info" @click="onSubmit">Submit Feedback </b-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '../../router';
import Preloader from './Preloader.vue';
import Vue from 'vue';

export default {
  components: {
    Preloader
  },
  data() {
    return {
      loading: false,
      show: false,
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      value5: 0,
      value6: 0
    };
  },
  async mounted() {
    setTimeout(() => {
      document.getElementsByClassName('overlay')[0].style.display = 'none';
      this.loading = true;
    }, 1000);
  },
  computed: {
    ...mapState('rating', ['rating']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('rating', ['postOne']),
    onSubmit() {
      if (this.$route.query.vendorCode) {
        const rating = {
          POnumber: this.POnumber,
          feedbackSubmitted: this.feedbackSubmitted,
          easeOfGettingOrder: this.value1,
          easeOfSupply: this.value2,
          easeOfDocumentationWhileDelivery: this.value3,
          easeOfReceivingPayments: this.value4,
          accessibilityForSRLaroundCommunication: this.value5,
          easeOfAccountReconciliation: this.value6
        };
        this.postOne({
          rating,
          query: {
            vendorCode: this.$route.query.vendorCode,
            vendorName: this.$route.query.vendorName
          }
        });
        this.show = true;
        setTimeout(() => {
          router.push('/thanks');
        }, 2000);
      } else {
        Vue.swal({
          title: 'Error',
          text: 'Please check the link its Invalid.',
          type: 'error',
          confirmButtonText: 'OK',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.title {
  font-weight: bold;
  text-align: center;
  margin-top: -3%;
  margin-bottom: 5%;
  color: #454545;
}
.feedback {
  color: #454545;
}
.submitButton {
  margin-top: 5%;
}
.logo {
  margin-top: -8%;
}
</style>
