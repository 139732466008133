<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="kycInstitutionalList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showReportDownload="false"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :downloadReportText="'Download Report'"
      downloadText="Download Data"
      emptyText="No Data found."
      @download="onExportCSV"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'KYCInstitutional',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'KYC Institutional',
      meta: [
        {
          name: 'description',
          content: `Manage KYC Institutional`
        }
      ]
    };
  },
  mounted() {
    this.listKYCInstitutional({ query: this.$route.query });
  },
  beforeRouteUpdate(to, from, next) {
    this.listKYCInstitutional({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'KYC Institutional List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Email',
          class: { 'text-center': true },
          textKey: 'email',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Designation',
          class: { 'text-center': true },
          textKey: 'designation',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Organization Name',
          class: { 'text-center': true },
          textKey: 'organisationName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Pincode',
          class: { 'text-center': true },
          htmlKey: 'pinCode',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Favourite Store',
          class: { 'text-center': true },
          htmlKey: 'favouriteStore',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'No. of Employees',
          class: { 'text-center': true },
          htmlKey: 'numberOfEmployees',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Approx. Budget Per Employee',
          class: { 'text-center': true },
          htmlKey: 'approxBudgetPerEmployee',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Remarks',
          class: { 'text-center': true },
          htmlKey: 'remarks',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          htmlKey: 'submittedByName',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          htmlKey: 'createdAt',
          width: '8%'
        }
      ],
      searchItems: ['Customer Name', 'Mobile']
    };
  },
  computed: {
    ...mapState('kyc', ['kycInstitutionalList', 'loading', 'pagination', 'downloadKycInstitutionalList'])
  },
  watch: {
    downloadKycInstitutionalList(newValue) {
      if (newValue) {
        let csv = `Row Number,Customer Name,Mobile,Email,Designation,Organisation Name,Address,Pin Code,Favourite Store,Number Of Employees,Approx. Budget Per Employee,Remarks,Submitted By Name,Roles,Store Id,Created At\n`;
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'KYCList.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('kyc', ['listKYCInstitutional', 'downloadInstitutional']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadInstitutional({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.downloadInstitutional({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    }
  }
};
</script>

<style></style>
