<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="kycList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showReportDownload="false"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :downloadReportText="'Download Report'"
      downloadText="Download Data"
      emptyText="No Data found."
      @download="onExportCSV"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'KYC',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'KYC',
      meta: [
        {
          name: 'description',
          content: `Manage KYC`
        }
      ]
    };
  },
  mounted() {
    // if (_.isEmpty(this.$route.query)) {
    //   this.listKYC({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listKYC({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listKYC({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'KYC List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '16%'
        },
        {
          type: 'string',
          headerText: 'Pin Code',
          class: { 'text-center': true },
          textKey: 'pinCode',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Favourite Store',
          class: { 'text-center': true },
          textKey: 'favouriteStore',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Gender',
          class: { 'text-center': true },
          textKey: 'gender',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Age',
          class: { 'text-center': true },
          htmlKey: 'age',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Communication Mode',
          class: { 'text-center': true },
          htmlKey: 'communicationMode',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          htmlKey: 'submittedByName',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          htmlKey: 'createdAt',
          width: '12%'
        }
      ],
      searchItems: ['Customer Name', 'Mobile']
    };
  },
  computed: {
    ...mapState('kyc', ['kycList', 'loading', 'baseUrl', 'pagination', 'downloadKycList'])
  },
  watch: {
    downloadKycList(newValue) {
      if (newValue) {
        let csv = `Row Number,Customer Name,Mobile,Address,Pin Code,Favourite Store,Gender,Age,Communication Mode,Submitted By,Roles,Store Id,Created At\n`;
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'KYCList.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('kyc', ['listKYC', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    }
  }
};
</script>
