<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="licenseList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showDownload="true"
      :showReportDownload="false"
      :showToolbar="true"
      :showFilter="true"
      :filterOptions="filterOptions"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :downloadReportText="'Download Report'"
      addText="Upload License"
      downloadText="Download Data"
      emptyText="No Data found."
      @download="onExportCSV"
      @add="onAdd"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'License',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'License',
      meta: [
        {
          name: 'description',
          content: `Manage License`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listLicense({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listLicense({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listLicense({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'License List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'License Name',
          class: { 'text-center': true },
          textKey: 'fileName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'License URL',
          class: { 'text-center': true },
          urlKey: 'fileUrl',
          width: '30%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Issuance Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Expiry date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        }
      ],
      searchItems: ['Store ID', 'File Name'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Expired-True', text: 'Expired-True' },
        { value: 'Expired-False', text: 'Expired-False' }
      ]
    };
  },
  computed: {
    ...mapState('license', ['licenseList', 'loading', 'baseUrl', 'pagination', 'downloadLicenseList'])
  },
  watch: {
    downloadLicenseList(newValue) {
      if (newValue) {
        let csv = `Row Number,License Name,License URL,Store Id, Issuance Date, Expiry Date, Submitted By, Uploaded At\n`;
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'LicenseList.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('license', ['listLicense', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    onAdd() {
      router.push('/license/new');
    }
  }
};
</script>
