<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col>
            <b-form-group id="group-title" label-for="input-title">
              <template v-slot:label>
                License Name
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                id="input-title"
                type="text"
                v-model="form.licenseName"
                placeholder="Enter License Name"
                :state="$v.form.licenseName.$dirty ? !$v.form.licenseName.$error : null"
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
              ></b-form-input>

              <b-form-invalid-feedback id="input-title-invalid">Please enter license name.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" lg="6">
            <template>
              Select Date
              <span class="text-danger">*</span>
            </template>
            <b-row>
              <b-col sm="12" lg="6">
                <b-input-group class="box-shadow2 mb-2">
                  <b-input-group-append>
                    <b-form-datepicker
                      button-only
                      id="start-datepicker"
                      placeholder="Start Date"
                      :min="minDate"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      v-model="form.startDate"
                      class="box-shadow3"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="example-input"
                    v-model="form.issueDate"
                    type="text"
                    placeholder="Issue Date"
                    autocomplete="off"
                    :class="{
                      '': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <b-input-group class="box-shadow2 mb-2">
                  <b-input-group-append>
                    <b-form-datepicker
                      button-only
                      id="end-datepicker"
                      placeholder="End Date"
                      :min="minDate"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      v-model="form.endDate"
                      class="box-shadow3"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="example-input"
                    v-model="form.expiryDate"
                    type="text"
                    placeholder="Expiry Date"
                    autocomplete="off"
                    :class="{
                      '': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Upload License
                <span class="text-danger">*</span>
              </template>
              <input type="file" @change="uploadFile($event)" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Store Id
                <span class="text-danger">*</span>
              </template>
              <multiselect
                v-model="storeId"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                placeholder="Select Store Id"
                label="text"
                track-by="value"
                :options="stores"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                class="box-shadow2"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                class="ml-2 box-shadow2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/license-upload` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'LicenseFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    licenseType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      licenseName: '',
      storeId: '',
      stores: [],
      file: [],
      formLoaded: true,
      form: {
        licenseName: null,
        issueDate: null,
        expiryDate: null,
        file: '',
        storeId: ''
      },
      dateTimeFormat: configService.get('format').pickerDateTime
      // minDate: moment().format('YYYY-MM-DD')
    };
  },
  validations() {
    const formValidation = {
      licenseName: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.licenseName.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.getStoreByUser();
    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new License' : 'Update License';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('license', ['loading', 'license']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('license', ['postOne']),
    ...mapActions('cstSubmission', ['getStoreByUser']),

    uploadFile(event) {
      this.file = event.target.files[0];
      const createBase64 = file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };
      createBase64(this.file).then(base64 => {
        this.form.file = base64;
      });
    },

    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      const license = {
        fileName: this.form.licenseName,
        active: true,
        startDate: this.form.issueDate,
        endDate: this.form.expiryDate,
        storeid: this.storeId.value,
        fileUrl: this.form.file
      };
      if (this.formType === 'new') {
        this.$emit('add', { license });
      } else {
        this.$emit('edit', { license });
      }
      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    license(newValue) {
      if (!this.license.id) {
        return;
      }
      // Loaded Video Deatils, assign to form
      this.form.licenseName = newValue.fileName;
      this.form.issueDate = moment(newValue.startDate).format('YYYY-MM-DD');
      this.form.expiryDate = moment(newValue.endDate).format('YYYY-MM-DD');
      this.storeId = newValue.storeid;

      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    store(newValue) {
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
  margin-top: 4%;
}
.date {
  margin-left: -10px;
}
.upload {
  margin-top: 8px;
}
.videoPlayer {
  width: 25%;
}
</style>
