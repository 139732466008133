<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="brandList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :steps="steps"
      :callbacks="callbacks"
      uploadText="Upload Brand Item List"
      downloadText="Download Brand Item List"
      emptyText="No Brand Items found. Please add new Brand Item."
      :showUpload="true"
      @delete="onDelete"
      @download="onExportCSV"
      @show-upload="onShowUpload"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'BrandList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Local Brand',
      meta: [
        {
          name: 'description',
          content: `Manage Brand Items`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedBrand') !== 'true') {
    //   this.$tours.myTour.start();
    // }

    if (_.isEmpty(this.$route.query)) {
      this.listBrand({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listBrand({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listBrand({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Local Brand Information List',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedBrand', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Category or Store Id to Filter the List of Local Brands.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Download the list of Local Brands.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Upload the list of Local Brands in CSV format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '7%'
        },
        {
          type: 'String',
          headerText: 'City Name',
          class: { 'text-center': true },
          textKey: 'cityName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Item Name',
          class: { 'text-center': true },
          textKey: 'itemName',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Brand Name',
          class: { 'text-center': true },
          textKey: 'brandName',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Description(Each/Kg/Gram)',
          class: { 'text-center': true },
          textKey: 'description',
          width: '16%'
        },
        {
          type: 'string',
          headerText: 'Product MRP',
          class: { 'text-center': true },
          textKey: 'mrp',
          width: '8%'
        },

        {
          type: 'string',
          headerText: 'Local Market Selling Price',
          class: { 'text-center': true },
          textKey: 'localSellingPrice',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Category(FMCG/Staples)',
          class: { 'text-center': true },
          textKey: 'category',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Submitted By ',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '7%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '7%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Category', 'Store Id']
    };
  },
  computed: {
    ...mapState('brand', ['loading', 'baseUrl', 'brandList', 'pagination', 'downloadBrandList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    downloadBrandList(newValue) {
      if (newValue.length) {
        let csv =
          'Row Number,Store ID,Store Name,City Name,Item Name,Brand Name,Description(Each/Kg/Gram),Product MRP,Local Market Selling Price,Category(FMCG/Staples),Submitted By\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Brand Items.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('brand', ['listBrand', 'deleteOne', 'upload', 'download']),
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },
    async onShowUpload() {
      const { value: brandFile } = await Vue.swal({
        title: 'Upload file for Brand Items..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Brand Items file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        footer:
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/BrandSample.csv" >Download sample upload file</a>'
      });
      if (brandFile) {
        const reader = new FileReader();
        reader.onload = () => {
          csv({
            noheader: true,
            output: 'csv'
          })
            .fromString(reader.result)
            .then(csvRow => {
              const inputs = csvRow.slice(1, csvRow.length).map(item => {
                return {
                  storeid: item[0].toUpperCase(),
                  storeName: item[1],
                  cityName: item[2],
                  itemName: item[3],
                  brandName: item[4],
                  description: item[5],
                  mrp: item[6],
                  localSellingPrice: item[7],
                  category: item[8]
                };
              });
              this.upload({ router, data: inputs });
            });
        };
        reader.readAsBinaryString(brandFile);
      }
    }
  }
};
</script>
