var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"group-title","label-for":"input-title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" License Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,35949535)},[_c('b-form-input',{class:{
                'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-title","type":"text","placeholder":"Enter License Name","state":_vm.$v.form.licenseName.$dirty ? !_vm.$v.form.licenseName.$error : null},model:{value:(_vm.form.licenseName),callback:function ($$v) {_vm.$set(_vm.form, "licenseName", $$v)},expression:"form.licenseName"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-title-invalid"}},[_vm._v("Please enter license name.")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[[_vm._v(" Select Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])],_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-input-group',{staticClass:"box-shadow2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"box-shadow3",attrs:{"button-only":"","id":"start-datepicker","placeholder":"Start Date","min":_vm.minDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1),_c('b-form-input',{class:{
                    '': !_vm.isDarkMode,
                    'bg-dark text-light placeholder-light': _vm.isDarkMode
                  },attrs:{"id":"example-input","type":"text","placeholder":"Issue Date","autocomplete":"off","disabled":""},model:{value:(_vm.form.issueDate),callback:function ($$v) {_vm.$set(_vm.form, "issueDate", $$v)},expression:"form.issueDate"}})],1)],1),_c('b-col',[_c('b-input-group',{staticClass:"box-shadow2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"box-shadow3",attrs:{"button-only":"","id":"end-datepicker","placeholder":"End Date","min":_vm.minDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('b-form-input',{class:{
                    '': !_vm.isDarkMode,
                    'bg-dark text-light placeholder-light': _vm.isDarkMode
                  },attrs:{"id":"example-input","type":"text","placeholder":"Expiry Date","autocomplete":"off","disabled":""},model:{value:(_vm.form.expiryDate),callback:function ($$v) {_vm.$set(_vm.form, "expiryDate", $$v)},expression:"form.expiryDate"}})],1)],1)],1)],2),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Upload License "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1163919067)},[_c('input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.uploadFile($event)}}})])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Store Id "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,2789158257)},[_c('multiselect',{ref:"multiselect",class:{
                'box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select Store Id","label":"text","track-by":"value","options":_vm.stores},model:{value:(_vm.storeId),callback:function ($$v) {_vm.storeId=$$v},expression:"storeId"}})],1)],1)],1),_c('b-form-group',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2",attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2 box-shadow2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/license-upload" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }